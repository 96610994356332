export const canaryCopy = {
  canaryPageHeader: '',
  pageHeader: '',
  canaryEmailIntroduction: '',
  emailIntroduction: '',
  modalHeader: '',
  modalDescription: '',
  lessSpamFeature: '',
  betterSearchFeature: '',
  fasterLoadingFeature: '',
  mobileHeder: '',
  mobileDesc: '',
  updatedPerformanceHeader: '',
  updatedPerformanceDescA: '',
  updatedPerformanceDescB: '',
  upgradeNowHeaderA: '',
  upgradeNowHeaderB: '',
  upgradeNowDescA: '',
  upgradeNowDescB: '',
  sideNavAria: '',
  sideNavHeader: '',
  sideNavSecondary: '',
  settingsAria: '',
  settingsHeader: '',
  settingsSecondary: '',
  spamAria: '',
  spamHeader: '',
  spamSecondary: '',
  bulkActionsAria: '',
  bulkActionsHeader: '',
  bulkActionsSecondary: '',
  signatureAria: '',
  signatureHeader: '',
  signatureSecondary: '',
  mobileOneAria: '',
  mobileOneHeader: '',
  mobileOneSecondary: '',
  mobileTwoAria: '',
  mobileTwoHeader: '',
  mobileTwoSecondary: '',
  mobileThreeAria: '',
  mobileThreeHeader: '',
  mobileThreeSecondary: '',
  mobileFourAria: '',
  mobileFourHeader: '',
  mobileFourSecondary: '',
  mobileFiveAria: '',
  mobileFiveHeader: '',
  mobileFiveSecondary: '',
  mobileSixAria: '',
  mobileSixHeader: '',
  mobileSixSecondary: '',
  skip: '',
  previous: '',
  next: '',
  finish: '',
  settings: '',
  getItLater: '',
  learnMore: '',
  upgradeNow: '',
  takeATour: '',
  seeMore: '',
  startTour: '',
  keepCurrentVersion: '',
  continueToMailbox: '',
};

import { KiteButton, KiteIcon } from '@kite/react-kite';
import styles from 'containers/styles/CanaryLandingPageContainer.module.scss';
import CanaryLandingPageHeader from 'components/header/canary-header';
import { TrackingHook, useTracking } from 'react-tracking';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Success,
  useContactMigrationMutation,
  useGetOptInStatusLazyQuery,
  usePostOptInStatusMutation,
  useSettingMigrationMutation,
} from 'generated/graphql';
import { postOptInStatusThunk } from 'thunks/canary/canaryPostThunk';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateIsCanary, updateIsOpen, updateModalName, updateModalProps } from 'slices/app';
import { Navigate, useNavigate } from 'react-router-dom';
import Footer from 'components/footer';
import { getOptInStatusThunk } from 'thunks/canary/getOptInStatusThunk';
import { getIsCanaryCompleted, getWebmail2URL, isWebmail2MigrationEnabled } from 'app-constants/appConfig';
import appRedirect from 'utils/appRedirect';
import { contactMigrationThunk } from 'thunks/migration/contactMigrationThunk';
import { settingMigrationThunk } from 'thunks/migration/settingMigrationThunk';
import AutoFocusHeader from 'components/common/AutoFocusHeader';
import { appConstants } from 'appConstants';
import { usePageTranslation } from 'translation/hooks';

type MigrationResponse = {
  payload: {
    success: Success;
    error?: {
      message: string;
    };
  };
};

const CanaryLandingPageContainer: React.FC = () => {
  const { t } = usePageTranslation('canaryCopy');
  const { trackEvent }: TrackingHook = useTracking({ page: 'canary', component: 'CanaryLandingPage' });
  const optInStatus = useAppSelector((state) => state.canarySlice.optInStatus);
  const isOptInStatusCalled = useAppSelector((state) => state.canarySlice.isOptInStatusCalled);
  const scrollToRef = useRef<HTMLDivElement>(null);

  const introductionHeaderRef = useRef<HTMLHeadingElement>(null);

  const isOpen = useAppSelector((state) => state.appSlice.isOpen);
  const [getOptInStatusHook] = useGetOptInStatusLazyQuery();
  const [contactMigrationHook] = useContactMigrationMutation();
  const [settingMigrationHook] = useSettingMigrationMutation();

  const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);

  const navigate = useNavigate();

  const [optInHook] = usePostOptInStatusMutation({
    variables: {
      optInType: 'canary',
      isOptInYes: true,
    },
  });

  const dispatch = useAppDispatch();

  useMemo(() => {
    if (!optInStatus) {
      trackEvent({ event: 'landingPageViewInit', url: 'canary-landing-page' });
    }
  }, [optInStatus]);

  useEffect(() => {
    if (optInStatus === '' && !isOptInStatusCalled) {
      dispatch(getOptInStatusThunk({ getOptInStatusHook }));
    }

    if (!optInStatus && isOptInStatusCalled) {
      dispatch(updateIsCanary(true));
      trackEvent({ event: 'landingPageViewCompleted' });

      if (!getIsCanaryCompleted()) {
        dispatch(updateIsOpen(true));
        dispatch(updateModalName('canaryModal'));
        dispatch(updateModalProps({ isCanary: true, hideCloseButton: true }));
        introductionHeaderRef?.current?.focus();
      }
    }

    if (optInStatus === 'yes') {
      dispatch(updateIsOpen(false));
    }
  }, [optInStatus]);

  const handleOptIn = async (isOptInYes: boolean, navigateTo?: string): Promise<void> => {
    setIsFirstTimeUser(true);
    const result = await dispatch(
      postOptInStatusThunk({
        optInHook,
        payload: {
          optInType: 'canary',
          isOptInYes,
        },
      })
    );

    // @ts-ignore
    if (result.payload.success) {
      trackEvent({ event: 'optIn' });

      if (isWebmail2MigrationEnabled()) {
        const contactResponse = (await dispatch(contactMigrationThunk({ contactMigrationHook }))) as MigrationResponse;
        const settingResponse = (await dispatch(settingMigrationThunk({ settingMigrationHook }))) as MigrationResponse;

        if (contactResponse.payload.success) {
          trackEvent({ event: 'contactMigrationStatus', success: true });
        }
        if (settingResponse.payload.success) {
          trackEvent({ event: 'settingMigrationStatus', success: true });
        }
        if (contactResponse.payload.error) {
          trackEvent({
            event: 'contactMigrationStatus',
            success: false,
            errorMessage: contactResponse.payload.error.message,
          });
        }
        if (settingResponse.payload.error) {
          trackEvent({
            event: 'settingMigrationStatus',
            success: false,
            errorMessage: settingResponse.payload.error.message,
          });
        }
      }

      navigate(navigateTo ?? '/user/tutorial');
      return;
    }

    trackEvent({ event: 'optOut' });
    appRedirect(getWebmail2URL());
  };

  const handleTryItLater = (): void => {
    trackEvent({ event: 'optOut' });
    appRedirect(getWebmail2URL());
  };

  const handleSeeMore = (): void => {
    scrollToRef.current && scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const optInAriaLabel = getIsCanaryCompleted() ? 'Take a Tour' : 'Upgrade Now';
  const continueToMailboxAriaLabel = 'Continue to Mailbox';

  const IntroductionHeaderSection = (): JSX.Element => {
    return (
      <section className={styles.introductionHeaderSection} ref={introductionHeaderRef}>
        <AutoFocusHeader
          customStyles={styles.header}
          content={getIsCanaryCompleted() ? t(`pageHeader`) : t('canaryPageHeader')}
        />
      </section>
    );
  };

  const EmailIntroductionSection = (): JSX.Element => {
    return (
      <section className={styles.emailIntroductionSection}>
        <div className={styles.image}>
          <img className={styles.leftPanelImage} src={appConstants.ui.EMAIL_INTRODUCTION_LINK} alt="" />
        </div>
        <div className={styles.rightPanel}>
          <p className={styles.rightPanelText}>
            {getIsCanaryCompleted() ? t(`emailIntroduction`) : t(`canaryEmailIntroduction`)}
          </p>
          <div className={styles.actionButton}>
            <KiteButton
              className={styles.rightPanelButton}
              onClick={() => handleOptIn(true)}
              aria-label={optInAriaLabel}
            >
              {getIsCanaryCompleted() ? t(`takeATour`) : t(`upgradeNow`)}
            </KiteButton>

            <KiteButton
              className={styles.rightPanelButton}
              onClick={() => handleOptIn(true, '/user/mail')}
              aria-label={continueToMailboxAriaLabel}
              legacy
              variant="secondary"
            >
              {t(`continueToMailbox`)}
            </KiteButton>
          </div>
        </div>
      </section>
    );
  };

  const UpdatedPerformanceSection = (): JSX.Element => {
    return (
      <section className={styles.updatedPerformanceSection}>
        <div>
          <h3 tabIndex={0} className={styles.leftPanelHeader}>
            {t(`updatedPerformanceHeader`)}
          </h3>
          <p className={styles.leftSecondaryText}>{t(`updatedPerformanceDescA`)}</p>
        </div>
        <div className={styles.image} ref={scrollToRef}>
          <img className={styles.rightPanelImage} src={appConstants.ui.UPDATED_PERFORMANCE_LINK} alt="" />
        </div>
      </section>
    );
  };

  const FeatureListSection = (): JSX.Element => {
    const features = [
      {
        icon: 'ki-desktop',
        primary: 'Less Spam',
        secondary: t(`lessSpamFeature`),
      },
      {
        icon: 'ki-search-previous',
        primary: 'Better Search',
        secondary: t(`betterSearchFeature`),
      },
      {
        icon: 'ki-fast-forward',
        primary: 'Faster Loading',
        secondary: t(`fasterLoadingFeature`),
      },
    ];

    const featuresArray = features.map(({ icon, primary, secondary }, index) => {
      return (
        <div
          key={`feat-${index}`}
          className={`${styles.featureListBox} ${index === 0 ? styles.filled : styles.unfilled}`}
        >
          <KiteIcon icon={icon} className={styles.icon} size="31px" />
          <h3 tabIndex={0} className={styles.primaryText}>
            {primary}
          </h3>
          <p className={styles.secondaryText}>{secondary}</p>
        </div>
      );
    });

    return <section className={styles.featureListSection}>{featuresArray}</section>;
  };

  const MadeForMobileSection = (): JSX.Element => {
    return (
      <section className={styles.madeForMobileSection}>
        <div className={styles.image}>
          <img className={styles.leftPanelImage1} src={appConstants.ui.MADE_FOR_MOBILE_LINK_1} alt="" />
          <img className={styles.leftPanelImage2} src={appConstants.ui.MADE_FOR_MOBILE_LINK_2} alt="" />
          <img className={styles.leftPanelImage3} src={appConstants.ui.MADE_FOR_MOBILE_LINK_3} alt="" />
        </div>
        <div className={styles.rightPanelContainer}>
          <h3 tabIndex={0} className={styles.rightPanelPrimary}>
            {t('mobileHeder')}
          </h3>
          <p className={styles.rightPanelSecondary}>{t(`mobileDesc`)}</p>
        </div>
      </section>
    );
  };

  const UpgradeNowSection = (): JSX.Element => {
    return (
      <section className={styles.upgradeNowSection}>
        <h3 tabIndex={0} className={styles.middlePanelPrimary}>
          {getIsCanaryCompleted() ? t(`upgradeNowHeaderA`) : t(`upgradeNowHeaderB`)}
        </h3>
        <p className={styles.middlePanelSecondary}>
          {getIsCanaryCompleted() ? t(`upgradeNowDescA`) : t(`upgradeNowDescB`)}
        </p>
        <div className={styles.middlePanelAction}>
          {!getIsCanaryCompleted() && (
            <KiteButton className={styles.cancelButton} onClick={handleTryItLater} aria-label={'Try it later'}>
              {t('keepCurrentVersion')}
            </KiteButton>
          )}

          <KiteButton className={styles.confirmButton} onClick={() => handleOptIn(true)} aria-label={optInAriaLabel}>
            {getIsCanaryCompleted() ? t(`startTour`) : t(`upgradeNow`)}
          </KiteButton>
        </div>
      </section>
    );
  };

  if (!isOptInStatusCalled) {
    return null;
  }

  // This block redirects already-opted-in users straight to mailbox. First time users will see the Tutorial.
  if (optInStatus === 'yes') {
    !isFirstTimeUser && dispatch(updateIsCanary(false));

    return <Navigate to={isFirstTimeUser ? '/user/tutorial' : '/user/mail/SU5CT1g%3D'} />;
  }

  return (
    <div className={`${styles.landingPageContainer} ${isOpen ? styles.modalOpen : ''}`}>
      <CanaryLandingPageHeader />
      <div className={`${styles.canaryMainContent} ${styles.flexColumn}`}>
        <IntroductionHeaderSection />
        <EmailIntroductionSection />
        <div className={styles.seeMoreContainer}>
          <KiteButton
            className={styles.button}
            icon={'ki-chevron-down-circle'}
            variant={'borderless'}
            onClick={handleSeeMore}
            aria-label={'See more'}
          >
            {t('seeMore')}
          </KiteButton>
        </div>
        <UpdatedPerformanceSection />
        <FeatureListSection />
        <MadeForMobileSection />
        <UpgradeNowSection />
      </div>
      <Footer largeFooter={true} />
    </div>
  );
};

export default CanaryLandingPageContainer;
